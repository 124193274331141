<template>
    <div class="not_found">
        <h1>404/NotFound</h1>
    </div>
</template>
  
<script>
export default {
  name: 'NotFound',
}
</script>